@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'Gotham';
    src: url("./fonts/Gotham-Book.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: block
}

@font-face {
    font-family: 'Gotham';
    src: url("./fonts/Gotham-Medium.woff2") format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: block
}

@font-face {
    font-family: 'Gotham';
    src: url("./fonts/Gotham-Bold.woff2") format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: block
}

.cursor-coin {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🎁</text></svg>") 16 0, auto;
}